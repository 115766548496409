// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WordUsageRow-module__helpTip___229bi{text-align:center;background-color:var(--iq-primary);border-radius:50%;width:15px;height:15px;font-size:60%;line-height:100%;vertical-align:middle;cursor:default}.WordUsageRow-module__helpTip___229bi:before{content:\"?\";font-weight:bold;color:#fff;font-size:100%;padding:0 2px 0 3px}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Usage/components/WordUsageRow.module.scss"],"names":[],"mappings":"AAAA,sCACE,iBAAkB,CAClB,kCAAmC,CACnC,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,aAAc,CACd,gBAAiB,CACjB,qBAAsB,CACtB,cAAe,CATjB,6CAYI,WAAY,CACZ,gBAAiB,CACjB,UAAW,CACX,cAAe,CACf,mBAAoB","file":"WordUsageRow.module.scss","sourcesContent":[".helpTip {\n  text-align: center;\n  background-color: var(--iq-primary);\n  border-radius: 50%;\n  width: 15px;\n  height: 15px;\n  font-size: 60%;\n  line-height: 100%;\n  vertical-align: middle;\n  cursor: default;\n\n  &:before {\n    content: \"?\";\n    font-weight: bold;\n    color: #fff;\n    font-size: 100%;\n    padding: 0 2px 0 3px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"helpTip": "WordUsageRow-module__helpTip___229bi"
};
module.exports = exports;
