// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UsageLimitPanelBody-module__prepayButton___22AGy{width:4rem;margin-bottom:1.5rem}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Usage/components/UsageLimitPanelBody.module.scss"],"names":[],"mappings":"AAAA,kDACE,UAAW,CACX,oBAAqB","file":"UsageLimitPanelBody.module.scss","sourcesContent":[".prepayButton {\n  width: 4rem;\n  margin-bottom: 1.5rem;\n}\n"]}]);
// Exports
exports.locals = {
	"prepayButton": "UsageLimitPanelBody-module__prepayButton___22AGy"
};
module.exports = exports;
