// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".PlanPanel-module__priceList___1CmmA{min-height:255px;display:flex;justify-content:center;align-items:center}.PlanPanel-module__listUnstyled___3-n6Q{margin-bottom:0}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Organization/components/PlanPanel.module.scss"],"names":[],"mappings":"AAAA,qCACE,gBAAiB,CACjB,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACpB,wCAGC,eAAgB","file":"PlanPanel.module.scss","sourcesContent":[".priceList {\n  min-height: 255px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.listUnstyled {\n  margin-bottom: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"priceList": "PlanPanel-module__priceList___1CmmA",
	"listUnstyled": "PlanPanel-module__listUnstyled___3-n6Q"
};
module.exports = exports;
