// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MonthlyCard-module__green___1YLNQ{color:#3cc864}.MonthlyCard-module__red___3LeSD{color:#ff4a4a}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/AdminMetric/css/MonthlyCard.module.scss"],"names":[],"mappings":"AAAA,mCACE,aAAc,CACf,iCAGC,aAAc","file":"MonthlyCard.module.scss","sourcesContent":[".green {\n  color: #3cc864;\n}\n\n.red {\n  color: #ff4a4a;\n}\n"]}]);
// Exports
exports.locals = {
	"green": "MonthlyCard-module__green___1YLNQ",
	"red": "MonthlyCard-module__red___3LeSD"
};
module.exports = exports;
