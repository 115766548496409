// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EditorSelection-module__suggestion___2aPPT{background-color:var(--iq-success)}.EditorSelection-module__replace___1BoP9{color:var(--iq-close) !important;text-decoration:line-through !important}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Article/EditorSelection.module.scss"],"names":[],"mappings":"AAAA,4CACE,kCAAmC,CACpC,yCAGC,gCAAiC,CACjC,uCAAwC","file":"EditorSelection.module.scss","sourcesContent":[".suggestion {\n  background-color: var(--iq-success);\n}\n\n.replace {\n  color: var(--iq-close) !important;\n  text-decoration: line-through !important;\n}\n"]}]);
// Exports
exports.locals = {
	"suggestion": "EditorSelection-module__suggestion___2aPPT",
	"replace": "EditorSelection-module__replace___1BoP9"
};
module.exports = exports;
