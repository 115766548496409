// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Editor-module__root___3p2h6{position:relative}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Article/components/Editor.module.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAkB","file":"Editor.module.scss","sourcesContent":[".root {\n  position: relative;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Editor-module__root___3p2h6"
};
module.exports = exports;
