// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".BlogInput-module__blogUrlSpan___3vhTy{text-align:left;min-width:85%;background-color:lavender;border-style:solid;border-width:1px;border-color:#d3d3d3}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Article/components/BlogInput.module.scss"],"names":[],"mappings":"AAAA,uCACE,eAAgB,CAChB,aAAc,CACd,yBAA0B,CAC1B,kBAAmB,CACnB,gBAAiB,CACjB,oBAAqB","file":"BlogInput.module.scss","sourcesContent":[".blogUrlSpan {\n  text-align: left;\n  min-width: 85%;\n  background-color: lavender;\n  border-style: solid;\n  border-width: 1px;\n  border-color: #d3d3d3;\n}\n"]}]);
// Exports
exports.locals = {
	"blogUrlSpan": "BlogInput-module__blogUrlSpan___3vhTy"
};
module.exports = exports;
