// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ToastNotificationCard-module__notificationCard___3-6oK{border-radius:5px;padding:10px;margin-top:10px}.ToastNotificationCard-module__notificationBody___1Sope{display:flex;justify-content:space-between}.ToastNotificationCard-module__notificationText___1_sRw{font-size:11px;margin-right:5px}.ToastNotificationCard-module__closeButton___2sjy7{font-size:18px}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Common/UI/ToastNotificationCard.module.scss"],"names":[],"mappings":"AAAA,wDACE,iBAAkB,CAClB,YAAa,CACb,eAAgB,CACjB,wDAGC,YAAa,CACb,6BAA8B,CAC/B,wDAGC,cAAe,CACf,gBAAiB,CAClB,mDAGC,cAAe","file":"ToastNotificationCard.module.scss","sourcesContent":[".notificationCard {\n  border-radius: 5px;\n  padding: 10px;\n  margin-top: 10px;\n}\n\n.notificationBody {\n  display: flex;\n  justify-content: space-between;\n}\n\n.notificationText {\n  font-size: 11px;\n  margin-right: 5px;\n}\n\n.closeButton {\n  font-size: 18px;\n}\n"]}]);
// Exports
exports.locals = {
	"notificationCard": "ToastNotificationCard-module__notificationCard___3-6oK",
	"notificationBody": "ToastNotificationCard-module__notificationBody___1Sope",
	"notificationText": "ToastNotificationCard-module__notificationText___1_sRw",
	"closeButton": "ToastNotificationCard-module__closeButton___2sjy7"
};
module.exports = exports;
