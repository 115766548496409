// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Page-module__toastContainer___9S9kL{position:fixed;list-style-type:none;z-index:2000}@media screen and (min-width: 992px){.Page-module__toastContainer___9S9kL{bottom:15px;left:2%;width:20%}}@media screen and (max-width: 991px){.Page-module__toastContainer___9S9kL{top:15px;left:25%;width:50%}}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Common/UI/Page.module.scss"],"names":[],"mappings":"AAAA,qCACE,cAAe,CACf,oBAAqB,CACrB,YAAa,CAEb,qCALF,qCAMI,WAAY,CACZ,OAAQ,CACR,SAAU,CAQb,CALC,qCAXF,qCAYI,QAAS,CACT,QAAS,CACT,SAAU,CAEb","file":"Page.module.scss","sourcesContent":[".toastContainer {\n  position: fixed;\n  list-style-type: none;\n  z-index: 2000;\n\n  @media screen and (min-width: 992px) {\n    bottom: 15px;\n    left: 2%;\n    width: 20%;\n  }\n\n  @media screen and (max-width: 991px) {\n    top: 15px;\n    left: 25%;\n    width: 50%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"toastContainer": "Page-module__toastContainer___9S9kL"
};
module.exports = exports;
