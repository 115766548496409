// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Modal-module__modal___1sPU4{position:fixed;bottom:0;left:0;z-index:1050;width:100%;height:100%;overflow:hidden;outline:0;background-color:rgba(0,0,0,0.5);transition:transform 0.3s ease-out}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Common/UI/Modal.module.scss"],"names":[],"mappings":"AAAA,6BACE,cAAe,CACf,QAAS,CACT,MAAO,CACP,YAAa,CACb,UAAW,CACX,WAAY,CACZ,eAAgB,CAChB,SAAU,CACV,gCAAoC,CACpC,kCAAmC","file":"Modal.module.scss","sourcesContent":[".modal {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  z-index: 1050;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  outline: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  transition: transform 0.3s ease-out;\n}\n"]}]);
// Exports
exports.locals = {
	"modal": "Modal-module__modal___1sPU4"
};
module.exports = exports;
