// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Dropdown-module__dropdownItem___R-o8T{width:100%;padding:0 1.1rem;clear:both;font-weight:400;color:#f8f9fa;text-align:inherit;white-space:nowrap;background-color:transparent;border:0;cursor:pointer;font-size:14px;transition:color 500ms linear}.Dropdown-module__dropdownItem___R-o8T:hover{color:#16181b;transition:color 500ms linear}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Common/UI/Dropdown.module.scss"],"names":[],"mappings":"AAAA,uCACE,UAAW,CACX,gBAAiB,CACjB,UAAW,CACX,eAAgB,CAChB,aAAc,CACd,kBAAmB,CACnB,kBAAmB,CACnB,4BAA6B,CAC7B,QAAS,CACT,cAAe,CACf,cAAe,CAGf,6BAA8B,CAC/B,6CAGC,aAAc,CAGd,6BAA8B","file":"Dropdown.module.scss","sourcesContent":[".dropdownItem {\n  width: 100%;\n  padding: 0 1.1rem;\n  clear: both;\n  font-weight: 400;\n  color: #f8f9fa;\n  text-align: inherit;\n  white-space: nowrap;\n  background-color: transparent;\n  border: 0;\n  cursor: pointer;\n  font-size: 14px;\n  -webkit-transition: color 500ms linear;\n  -ms-transition: color 500ms linear;\n  transition: color 500ms linear;\n}\n\n.dropdownItem:hover {\n  color: #16181b;\n  -webkit-transition: color 500ms linear;\n  -ms-transition: color 500ms linear;\n  transition: color 500ms linear;\n}\n"]}]);
// Exports
exports.locals = {
	"dropdownItem": "Dropdown-module__dropdownItem___R-o8T"
};
module.exports = exports;
