// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WordsUsedPanel-module__usageTable___3wtTW tr:first-child td{border-top:none}.WordsUsedPanel-module__dataTablesWrapper___1pAXK{width:100%;border-bottom:none}\n", "",{"version":3,"sources":["/home/pt-staging/af/rails/releases/20240501175820/app/javascript/Usage/components/WordsUsedPanel.module.scss"],"names":[],"mappings":"AAAA,6DACE,eAAgB,CACjB,kDAGC,UAAW,CACX,kBAAmB","file":"WordsUsedPanel.module.scss","sourcesContent":[".usageTable tr:first-child td {\n  border-top: none;\n}\n\n.dataTablesWrapper {\n  width: 100%;\n  border-bottom: none;\n}\n"]}]);
// Exports
exports.locals = {
	"usageTable": "WordsUsedPanel-module__usageTable___3wtTW",
	"dataTablesWrapper": "WordsUsedPanel-module__dataTablesWrapper___1pAXK"
};
module.exports = exports;
